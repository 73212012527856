import { getObjectValueByPath } from '../helpers';
import ERRORS_MAP from './errorsMap';
import { getServerErrorText } from './serverErrorsCodes';

export const walletErrors = [
  'MGMW_EX_4001|||Bad data',
  'MGMW_EX_3100|||Cannot create Ewallet',
  'MGMW_EX_3100|||Wallet Account Error',
  'MGMW_EX_3101|||Wallet Management Error',
  'MGMW_EX_4001|||Wallet error',
];

export const errorCodesMap = {
  MGMW_VE_9003: { id: 'Validation_Invalid' },
  MGMW_VE_9002: { id: 'Validation_TooMany' },
};

export function setServerErrors({ body, setFormikState, errorsMap = {} }) {
  if (!body.errors) return false;
  let serverErrors = mapServerErrorsToFormErrors(body.errors, {
    ...ERRORS_MAP.DEFAULT,
    ...errorsMap,
  });
  // checking to see if there are any backend validation errors
  if (!serverErrors.formError) {
    if (!body.errors.ERROR) {
      serverErrors.formError = {
        id: 'VALIDATION_ERROR',
        title: 'Error_Validation',
        errors: getValidationErrors(body.errors),
      };
    }
  }
  return setFormikState(state => {
    return { ...state, status: { ...state.status, serverErrors } };
  });
}

const getValidationErrors = errors => {
  const errorsMap = {};
  Object.keys(errors).forEach(v => {
    const errorCode = errors[v][0].split('|||')[0];
    errorsMap[v] = errorCodesMap[errorCode];
  });
  return errorsMap;
};

export function mapServerErrorsToFormErrors(serverErrors, errorsMap) {
  const errors = Object.entries(errorsMap).reduce((acc, curr) => {
    const fieldName = curr[0];
    const serverErrorPath = curr[1];
    const serverError =
      serverErrorPath && serverErrorPath.constructor === Array
        ? serverErrorPath.reduce((errors, path) => {
            const error = getObjectValueByPath(serverErrors, path);
            return error ? [...errors, ...error] : errors;
          }, [])
        : getObjectValueByPath(serverErrors, serverErrorPath);

    if (serverError && serverError.constructor === Array && serverError.length)
      acc[fieldName] = convertServerErrorToText({
        serverError: serverError[0],
        fieldName,
        errorRef: serverErrors.REF ? serverErrors.REF[0] : null, // TODO: Backend should not be sending REF as an array
      });

    return acc;
  }, {});

  errors.formError = errors.formHelp || errors.formError;
  return errors;
}

export function convertServerErrorToText({ serverError, fieldName, errorRef }) {
  const errorWithArgs = serverError.split('|||');
  const errorCode = fieldName === 'formHelp' ? 'FORM_HELP' : errorWithArgs[0];
  const errorArgs = errorWithArgs[1];
  const errorText = getServerErrorText({
    fieldName,
    errorCode,
    errorArgs,
    errorRef,
  });
  return errorText;
}

export const bodyHasError = (body, options?) => {
  if (!body || !body.errors || !body.errors.ERROR) return false;

  if (options) {
    const errors = body.errors.ERROR;
    const { exactError, containsExactError, errorCodes } = options;

    if (exactError) {
      return Array.isArray(errors) && errors.includes(exactError);
    }

    if (containsExactError) {
      return errors.some(code => containsExactError.includes(code));
    }

    if (errorCodes) {
      const codes = errorsWithOnlyCodes(errors);
      return codes.some(code => errorCodes.includes(code));
    }
  }

  return true;
};

export function errorsWithOnlyCodes(errors = []) {
  if (!errors) return [];

  if (Array.isArray(errors)) {
    return errors.map(error => error.split('|||')[0]);
  } else if (typeof errors === 'object') {
    return Object.keys(errors);
  }
}
