// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import { Trans, CreditCardIcon } from '../..';
import { TxPaymentCardForm, TxPaymentCardFormMode } from 'texkit-ui/forms';

// Helpers

const AddPaymentCard = ({ showForm }) => {
  const { selectedLanguage } = useSelector(({ language }) => ({
    selectedLanguage: language.selected,
  }));
  return (
    <>
      <div className={'payment-card'}>
        <CreditCardIcon name="blank" />
        <div className="payment-add-text">Add Card</div>
      </div>
      {showForm && (
        <TxPaymentCardForm
          acceptedCards={['VISA', 'MasterCard', 'Discover', 'American Express']}
          billingAddressTitle={
            <Trans file="Payment" id="BillingAddressTitle" />
          }
          billingAddressSubTitle={
            <Trans file="Payment" id="BillingAddressSubTitle" />
          }
          iFrameUrl="about:blank"
          mode={TxPaymentCardFormMode.TransactionAdd}
          onSubmit={() => {}}
          onCardFieldMessage={() => {}}
          paymentMethodTitle={
            <Trans file="Payment" id="CardInformationTitle" />
          }
          paymentMethodSubTitle={
            <Trans file="Payment" id="PaymentMethodSubTitle" />
          }
          spanish={selectedLanguage === 'es'}
          showSaveCardOption
          saveCardOptionLabel={<Trans file="Payment" id="SaveCardToAccount" />}
        />
      )}
    </>
  );
};

AddPaymentCard.propTypes = {
  showForm: PropTypes.bool.isRequired,
};

export default AddPaymentCard;
