// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { formikInjectedPropsTypes } from '../../../lib/validation/propTypes/formikPropTypes';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';

// Components
import { Trans, CreditCardIcon, InputZipCode } from '../..';
import { TextButton } from 'texkit/dist/components/Buttons';

// Actions
import { openModal } from '../../../reducers/modal/modalActions';

// Helpers
import { paymentAccountsData } from '../../../lib/data';
import { formatExpirationDate } from '../../../lib/helpers';

const PaymentCard = ({
  className,
  paymentAccount = {},
  showCvv,
  formikProps,
  showEditButton,
  isCardExpired,
  onEdit = () => {},
}) => {
  const dispatch = useDispatch();
  const { cardType, cardLast4, expirationDate } = paymentAccount;

  let fontStyle;
  if (showCvv) {
    fontStyle = className = 'payment-card-selected';
  } else {
    fontStyle = className = 'payment-card-name';
  }

  const handleWhatsThisClick = () => {
    const location = window.location.pathname;
    // TODO - Add agencies that accept Amex in future stories
    const agencies = [];
    const showAmex = agencies.some(agency => location.includes(agency));

    dispatch(openModal('WhatsThisModal', { showAmex }));
  };

  const dangerTextColor = isCardExpired ? 'text-danger' : '';

  return (
    <>
      <div className={classnames('payment-card', className)}>
        <CreditCardIcon
          name={
            cardType && paymentAccountsData[cardType]
              ? paymentAccountsData[cardType].icon
              : ''
          }
        />
        <div className="payment-card-info">
          <div className="payment-card-column">
            <div
              className={`${fontStyle} payment-card-number ${dangerTextColor}`}
            >
              •&nbsp;•&nbsp;•&nbsp;&nbsp;{cardLast4}
            </div>
            <span
              className={`${fontStyle} payment-card-name ${dangerTextColor}`}
            >
              {cardType && paymentAccountsData[cardType] ? (
                <Trans file="Payment" id={cardType} />
              ) : (
                ''
              )}
            </span>

            <span
              className={`${fontStyle} payment-card-expiration ${dangerTextColor}`}
            >
              {isCardExpired ? (
                <Trans file="Todo" id="TodoListState_Expired" />
              ) : (
                <Trans file="Todo" id="TodoListState_Todo" />
              )}{' '}
              {formatExpirationDate(expirationDate)}
            </span>
          </div>
        </div>
      </div>
      <div>
        {showEditButton && (
          <TextButton onClick={() => onEdit(paymentAccount)}>
            <Trans
              file="Payment"
              id="UpdateCardInformation"
              fallback="Update card information"
            />
          </TextButton>
        )}
      </div>
      {showCvv && (
        <>
          <InputZipCode
            className="payment-card-security-code"
            name="cvv"
            label={<Trans file="Payment" id="SecurityCode" />}
            labelPosition="above"
            minlength="3"
            formikProps={formikProps}
            required={true}
            maxlength="4"
          />
          <a onClick={handleWhatsThisClick}>
            <Trans file="Payment" id="WhatIsThis" />
          </a>
        </>
      )}
    </>
  );
};

PaymentCard.propTypes = {
  className: PropTypes.string,
  paymentAccount: PropTypes.shape({
    paymentAccountId: PropTypes.string.isRequired,
    paymentAccountType: PropTypes.string.isRequired,
    cardNumber: PropTypes.string.isRequired,
    expirationDate: PropTypes.string.isRequired,
  }),
  showCvv: PropTypes.bool.isRequired,
  formikProps: formikInjectedPropsTypes.isRequired,
  showEditButton: PropTypes.bool,
  onEdit: PropTypes.func,
  isCardExpired: PropTypes.bool,
};

export default PaymentCard;
