//@flow

// Vendors
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

// Components
import { FormError, Modal, Trans } from '../..';
import { TxButton, TxButtonMode } from 'texkit-ui';
import { TxPaymentCardForm, TxPaymentCardFormMode } from 'texkit-ui/forms';

// Actions
import {
  addPaymentAccount,
  getPaymentAccounts,
  getPaymentCardFrameUrl,
} from '../../../../reducers/payment/paymentActions';
import { closeModal } from '../../../../reducers/modal/modalActions';

// Helpers
import { states } from '../../../../lib/data';

import './add-payment-account-modal.scss';

const serverErrors = {
  MGMW_EX_4000: {
    title: 'WalletBadPayloadTitle',
    id: 'WalletBadPayloadDesc',
    type: 'danger',
  },
  MGMW_EX_4001: {
    title: 'WalletServerErrorTitle',
    id: 'WalletServerErrorDesc',
    type: 'warning',
  },
};

const cardTypeMap = {
  VISA: 'VISA',
  DISCOVER: 'DISC',
  AMEX: 'AMEX',
  MC: 'MC',
};

const AddPaymentAccountModal = () => {
  const dispatch = useDispatch();
  const [iFrameUrl, setIFrameUrl] = useState('about:blank');
  const [cardData, setCardData] = useState({});
  const [errorInfo, setErrorInfo] = useState();
  const { modal, selectedLanguage } = useSelector(state => ({
    modal: state.modal || {},
    selectedLanguage: state.language.selected,
  }));

  useEffect(() => {
    const fetchIframeUrl = async () => {
      try {
        const url = await dispatch(getPaymentCardFrameUrl());
        setIFrameUrl(url);
      } catch (error) {
        console.error('Error fetching iframe URL:', error);
      }
    };

    if (modal.activeModal === 'AddPaymentAccountModal') {
      fetchIframeUrl();
    }
  }, [modal.activeModal]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const state = states.find(state => state.label === values.state);
    const payload = {
      paymentAccountId: cardData.token,
      cardType: cardTypeMap[cardData.cardtype],
      cardLast4: cardData.token.slice(-4),
      expirationDate: values.expirationDate.replace('/', ''),
      paymentAccountName: values.name,
      address: {
        streetAddress: values.streetAddress,
        addressLine2: values.streetAddress2 || '',
        city: values.city,
        state: state.value || '',
        postalCode: values.zip,
      },
    };

    try {
      await dispatch(addPaymentAccount(payload));
      dispatch(getPaymentAccounts());
      dispatch(closeModal('AddPaymentAccountModal'));
    } catch (error) {
      const { code } = error;
      if (code) {
        setErrorInfo(serverErrors[code]);
      } else {
        console.error('Payment account error');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleCardFieldMessage = data => {
    if (data.success) {
      setCardData(data);
    } else {
      console.log('onCardFieldMessage', data);
    }
  };

  return (
    <Modal
      name="AddPaymentAccountModal"
      className="add-payment-account-modal"
      disableOnClickOutside="true"
      onOpen={() => setErrorInfo()}
    >
      <h2 className="h1 modal-title">
        <Trans file="Payment" id="AddCardTitle" />
      </h2>
      <Formik onSubmit={handleSubmit}>
        {formikProps => {
          const {
            isSubmitting,
            handleChange,
            handleBlur,
            values,
            errors,
          } = formikProps;

          let isValid = true;
          return (
            <form onSubmit={formikProps.handleSubmit}>
              <TxPaymentCardForm
                billingAddressTitle={
                  <Trans file="Payment" id="BillingAddressTitle" />
                }
                iFrameUrl={iFrameUrl}
                mode={TxPaymentCardFormMode.AccountSettingsAdd}
                onFieldChange={handleChange}
                onFieldBlur={handleBlur}
                onCardFieldMessage={handleCardFieldMessage}
                paymentMethodTitle={
                  <Trans file="Payment" id="PaymentMethodTitle" />
                }
                spanish={selectedLanguage === 'es'}
                values={values}
              />

              <FormError
                hasError={Boolean(errorInfo)}
                message={errorInfo}
                formikProps={formikProps}
              />

              <TxButton
                mode={TxButtonMode.Primary}
                type="submit"
                disabled={!isValid || isSubmitting || !cardData.token}
              >
                <Trans file="Payment" id="SavePaymentMethod" />
              </TxButton>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddPaymentAccountModal;
